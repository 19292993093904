// @flow
import { type JSSThemeDefaultTooltipType } from '../JSSThemeDefaultTooltip';

export const JSSThemeTooltip13: JSSThemeDefaultTooltipType = themeVars => {
  return {
    Tooltip: {
      tooltipText: {},
      right: {
        '&:before': {},
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60013: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByTypeTitle: {
        marginBottom: margins.md,
      },
    },
    PromotionsWrapper: {
      promotionsWrapper: {
        padding: margins.xm,
        '@media (pointer: coarse)': {
          background: primary,
          color: white,
        },
      },
      promotionsHeading: {
        fontSize: fontSizes.xxl,
      },
    },
    PromotionUI: {
      bannerContentHeader: {
        fontSize: fontSizes.xxxxl,
        padding: [margins.md, 0, 0],
        fontWeight: 'bold',
        color: white,
      },
      bannerContentTitle: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        color: white,
      },
      itemSecondTitle: {
        marginTop: margins.lg,
      },
      promoItemContainer: {
        '@media screen and (min-width: 1300px)': {
          flexBasis: '100%',
        },
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems13: JSSThemeDefaultBannerItemsType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text2, border2, border1 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: white,
        padding: margins.xm,
      },
      bannerContentFill: {
        padding: '0',
      },
      bannerContentSportsbookCarousel: {
        padding: [15, 45, 45],
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        padding: margins.lg,
        justifyContent: 'flex-end',
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentPromo: {
        padding: [margins.md],
      },
      headerSportsbookCarousel: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
        textShadow: 'none',
        color: white,
        margin: '30px 0 0',
        lineHeight: 'auto',
      },
      headerPromo: {
        fontSize: '26px',
        lineHeight: 'normal',
        fontWeight: 'bold',
        textShadow: '3px 3px 10px #000',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.sm,
        textShadow: 'none',
        color: white,
        padding: [margins.xs, 0, 0],
      },
      descriptionMobile: {
        color: white,
      },
      descriptionCasinoCarousel: {
        fontSize: [fontSizes.lg],
        color: white,
        padding: [margins.xs, 0],
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.lg,
        lineHeight: '20px',
        padding: '0',
      },
      textWrapperFill: {
        padding: '0',
        color: white,
        textShadow: '3px 3px 10px #000',
      },
      buttonsWrapperSportsbookCarousel: {
        margin: 0,
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        minHeight: 100,
        height: 140,
        maxHeight: 140,
        alignItems: 'left',
        justifyContent: 'center',
      },
      description: {
        textAlign: 'left',
        lineHeight: 'auto',
        fontSize: fontSizes.md,
        fontWeight: 'normal',
      },
      header: {
        fontSize: fontSizes.xxl,
        lineHeight: 'auto',
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: '0',
        marginRight: margins.md,
        background: 'none',
        borderRadius: 5,
      },
      heightFix: {
        minHeight: '258px',
      },
      imgContainer: {
        height: '100%',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        flex: 'unset',
        width: '30%',
        minWidth: '250px',
        height: '100%',
        padding: '0',
        backgroundColor: 'none',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: border2,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: border1,
      },
    },
    SideBanner: {
      containerWithContent: {
        backgroundPosition: 'left center',
        padding: '0',
        display: 'flex',
      },
    },
    BannerItemCasinoCarousel: {
      mobile: {
        height: '200px',
        '&:after': {
          background: `linear-gradient(
            to right,
            rgba(10, 13, 50, 1) 0%,
            rgba(10, 13, 50, 0) 10%,
            rgba(10, 13, 50, 0) 100%
          )`,
        },
      },
    },
  };
};

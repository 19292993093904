// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm60013: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background3, background4, white, black, text1 },
    },
  } = themeVars;
  return {
    PasswordResetWithEmail: {
      contactUsLink: {
        color: white,
        '&:hover': {
          color: white,
        },
      },
      passwordResetDebounce: {
        color: 'inherit',
        '@media (pointer: coarse)': {
          color: 'inherit',
        },
      },
    },
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.xm],
        backgroundColor: background3,
        background:
          'linear-gradient(90deg, rgb(74, 127, 18) 22%, rgb(13, 75, 36) 100%)',
        borderRadius: '4px',
        transition: '.3s ease-in',
        fontSize: fontSizes.md,
        marginRight: margins.xs,
        boxShadow: '0 5px 15px rgba(74, 127, 18, .5)',
        '&:hover': {
          backgroundColor: background3,
          background:
            'linear-gradient(90deg, rgb(74, 127, 18) 22%, rgb(13, 75, 36) 100%)',
          boxShadow: '0 5px 15px rgba(74, 127, 18, .8)',
        },
      },
    },
    DepositButton: {
      link: {
        fontWeight: 'bold',
        '&:hover': {
          opacity: '1',
        },
      },
      iconWrap: {
        marginRight: margins.md,
      },
    },
    LoginFormDesktop: {
      popUpWrapper: {
        background: background4,
        color: white,
        '&:before': {
          background: background4,
        },
      },
      forgotText: {
        color: 'white',
      },
    },
    MobileAuthForm: {
      wrapper: {
        color: white,
      },
      link: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
      },
      label: {
        color: black,
        fontSize: fontSizes.md,
        '@media (pointer: coarse)': {
          color: white,
        },
      },
    },
    LoginPage: {
      wrapper: {
        color: text1,
      },
      wrapperMobile: {
        color: text1,
      },
    },
  };
};

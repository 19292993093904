// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu13: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text1, background1 },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
        color: white,
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        fontWeight: 'bold',
        '&:hover, &:focus': {
          color: text1,
        },
      },
      menuItemActive: {
        color: text1,
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: background1,
        },
      },
    },
  };
};

// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60013,
  JSSThemeVariables60013,
  getJSSThemePatterns60013,
} from './JSSTheme60013';
import { GeoblockPage60013 } from './GeoblockPage60013';
// $FlowIgnore
import '../../../styles/application-koltron.scss';
import Cookies from 'js-cookie';
import { changeCurrentLocale } from '../../utils';

if (
  !Cookies.get('lang') &&
  __OSG_CONFIG__.specificLocaleForNonAuthorizedUsers
) {
  changeCurrentLocale(__OSG_CONFIG__.specificLocaleForNonAuthorizedUsers);
}

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60013')).FullDesktop60013,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile60013')).FullMobile60013,
  }),
  JSSTheme: JSSTheme60013,
  JSSThemeVariables: JSSThemeVariables60013,
  JSSThemePatterns: getJSSThemePatterns60013,
  GeoblockPage: GeoblockPage60013,
});

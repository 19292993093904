// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: {
        error,
        text1,
        text3,
        neutral1,
        border2,
        white,
        black,
        accent,
        error3,
        primary,
      },
    },
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: accent,
        boxShadow: 'none',
        paddingTop: margins.xs,
      },
      info: {
        background: 'transparent',
      },
    },
    BetslipTotals: {
      root: {
        borderTop: `none`,
      },
      summaryLabel: {
        color: white,
      },
      summaryValue: {
        color: white,
      },
    },
    BetslipSelection: {
      common: {
        background: white,
        borderRadius: '2px',
      },
    },
    EachWayButton: {
      root: {
        color: black,
      },
      label: {
        '&:after': {
          border: `3px solid ${text3}`,
        },
        '&:before': {
          border: `1px solid ${border2}`,
          background: neutral1,
        },
      },
    },
    BetslipErrors: {
      list: {
        borderRadius: 0,
        background: text1,
      },
      listItem: {
        color: error,
      },
    },
    BetslipInput: {
      container: {
        background: 'transparent',
        borderRadius: '0 0 2px 2px',
        padding: [margins.xs, 0, 0],
      },
      label: {
        color: black,
        fontSize: fontSizes.sm,
        padding: '0 0 5px',
      },
    },
    NonSingleBody: {
      selectionInfo: {
        color: black,
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: black,
      },
      marketDescription: {
        fontSize: fontSizes.sm,
        color: black,
      },
      priceDescription: {
        fontSize: fontSizes.sm,
        color: black,
      },
      info: {
        fontSize: '13px',
      },
      remove: {
        color: black,
      },
      liveLabel: {
        verticalAlign: 'top',
        marginTop: 2,
        display: 'inline-block',
      },
    },
    SelectionState: {
      closed: {
        color: white,
        background: error3,
        fontSize: fontSizes.sm,
      },
      suspended: {
        color: white,
        background: error3,
        fontSize: fontSizes.sm,
      },
    },
    SingleSelectionStaticInfo: {
      eventDescription: {
        color: black,
      },
      marketDescription: {
        color: black,
      },
      priceDescription: {
        color: black,
      },
    },
    Receipt: {
      root: {
        background: primary,
        boxShadow: 'none',
      },
      controls: {
        background: 'transparent',
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: white,
        },
      },
    },
  };
};

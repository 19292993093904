// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues13: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { background4, white },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background4,
      },
      link: {
        '&:active, &:focus': {
          color: white,
        },
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader13: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background1,
      },
      authContentWrap: {
        marginRight: margins.md,
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        '@media screen and (max-width: 1200px)': {
          width: 150,
        },
      },
      logo: {
        width: '100px',
        height: 'auto',
        marginLeft: margins.md,
        marginTop: 0,
        '@media screen and (max-width: 1200px)': {
          width: '80%',
        },
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60013: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { text2 },
    },
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        boxShadow: 'none',
        color: text2,
      },
    },
    OPMForms: {
      submitButton: {
        margin: '20px 50%',
        transform: 'translateX(-50%)',
      },
      backButtonWraper: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: text2,
        width: 300,
      },
      accountPageRightContent: {
        boxShadow: '3px 0px 10px rgba(0, 0, 0, 0.1)',
      },
    },
    OPMPageComponent: {
      heading: {
        padding: [0, margins.md],
      },
    },
    PendingWithdrawal: {
      wrapper: {
        padding: margins.md,
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets13: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteBorder2 },
      colorTypes: { border2, primary, white },
    },
  } = themeVars;
  return {
    ConfirmationBox: {
      confirmation: {
        background: border2,
      },
      text: {
        color: white,
      },
      loading: {
        color: white,
      },
    },
    CashOut: {
      success: whiteBorder2,
    },
    MyBets: {
      content: {
        background: primary,
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch13: JSSThemeDefaultSearchType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: {
        white,
        accent,
        text2,
        text1,
        background2,
        primary,
        neutral1,
      },
    },
  } = themeVars;
  return {
    SearchBar: {
      searchContainer: {
        background: accent,
      },
      resultContainer: {
        background: text2,
        color: white,
        maxHeight: `calc(100vh - ${134 + 23}px)`,
      },
      inputContainer: {
        padding: [margins.md, margins.xs],
      },
      input: {
        background: white,
        margin: 0,
        borderRadius: [margins.xl, 0, 0, margins.xl],
      },
      iconContainer: {
        background: white,
        borderRadius: [0, margins.xl, margins.xl, 0],
      },
    },
    SportsbookSearchResult: {
      title: {
        color: '#fff',
      },
      description: {
        color: text1,
        '&:hover': {
          color: primary,
        },
      },
      marketCount: {
        background: '#fff',
        fontWeight: 'bold',
        color: background2,
        '&:hover': {
          color: primary,
        },
      },
      sportsbookSearchResultOutcomesWrap: {
        borderTop: `1px solid ${neutral1}`,
      },
    },
  };
};

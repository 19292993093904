// @flow
import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';

export const JSSThemeFooter60013: JSSThemeDefaultFooterType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background5, text4 },
    },
  } = themeVars;
  return {
    FooterDesktop: {
      footer: {
        background: background5,
      },
      link: {
        color: text4,
      },
      heading: {
        color: text4,
      },
    },
    FooterMobile: {
      footer: {
        background: background5,
        color: text4,
      },
      link: {
        color: text4,
      },
      heading: {
        color: text4,
      },
      allRights: {
        color: text4,
      },
    },
  };
};

// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60013';
import { JSSThemeMyBets60013 } from './JSSThemeMyBets60013';
import { JSSThemePromotions60013 } from './JSSThemePromotions60013';
import { JSSThemeSitemap60013 } from './JSSThemeSitemap60013';
import { JSSThemeSportsMenu60013 } from './JSSThemeSportsMenu60013';
import { JSSThemeForm60013 } from './JSSThemeForm60013';
import { JSSThemeBannerItems60013 } from './JSSThemeBannerItems60013';
import { JSSThemeHeaderMobile60013 } from './JSSThemeHeaderMobile60013';
import { JSSThemeOutcomes60013 } from './JSSThemeOutcomes60013';
import { JSSThemeHeaderMenu60013 } from './JSSThemeHeaderMenu60013';
import { JSSThemeHeader60013 } from './JSSThemeHeader60013';
import { JSSThemeBalance60013 } from './JSSThemeBalance60013';
import { JSSThemeTooltip60013 } from './JSSThemeTooltip60013';
import { JSSThemeBonuses60013 } from './JSSThemeBonuses60013';
import { JSSThemeCoupons60013 } from './JSSThemeCoupons60013';
import { JSSThemeAuthForm60013 } from './JSSThemeAuthForm60013';
import { JSSThemePopularLeagues60013 } from './JSSThemePopularLeagues60013';
import { JSSTheme60013Resets } from './JSSTheme60013Resets';
import { JSSThemeCasino60013 } from './JSSThemeCasino60013';
import { JSSThemeSearch60013 } from './JSSThemeSearch60013';
import { JSSThemeAccountSection60013 } from './JSSThemeAccountSection60013';
import { JSSThemeFooter60013 } from './JSSThemeFooter60013';
import { JSSThemeLive60013 } from './JSSThemeLive60013';

export const colorTypes60013: ColorTypes = {
  ...colorTypes,
  primary: '#4A8013',
  text1: '#0D4B24',
  text2: '#0d4b24',
  text3: '#fafafa',
  text4: '#e3e3e3',
  text5: '#403f3c',
  background1: '#89C64F',
  background2: '#5B5B5E',
  background3: '#89C64F',
  background4: '#0D4B24',
  background5: '#48484a',
  background6: '#f6f7f9',
  accent: '#4A8013',
  success: '#21a22d',
  error: '#fb4b34',
  error2: '#ff5a2f',
  error3: '#f9442c',
  warning: 'orange',
  inform: '#89C64F',
  neutral1: '#6e6e6e',
  neutral2: '#dbdbdb',
  neutral3: '#89C64F',
  neutral4: '#D4EABF',
  border1: '#0D4B24',
  border2: '#4b7e12',
};

const pick = ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral2,
  whiteNeutral1,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
}) => ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral1,
  whiteNeutral2,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
});

export const colorCombinations60013 = getColorCombinations(
  colorTypes60013,
  pick
);

export const JSSThemeVariables60013: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-open',
      fontFamily: 'osg-open, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'Open/opensans-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'Open/opensans-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes60013,
    colorCombinations: colorCombinations60013,
  },
};

export const JSSTheme60013: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteBg4,
        whiteBg2,
        whiteBg1,
        whiteBorder2,
        text2White,
      },
      colorTypes: {
        neutral4,
        text1,
        text2,
        accent,
        white,
        neutral1,
        black,
        background4,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    Heading: {
      common: {
        background: 'none',
        color: text1,
        fontSize: fontSizes.xxxl,
        letterSpacing: 'normal',
        padding: [margins.xs, margins.xm, 0],
        '&.headingMobile': {
          background: accent,
          color: white,
        },
      },
    },
    mybets: JSSThemeMyBets60013(themeVars),
    Button: {
      root: {
        borderRadius: '2px',
        lineHeight: '32px',
        padding: '0 15px',
        fontWeight: 'bold',
        ...whiteBg2,
      },
      primary: {
        ...whiteBg1,
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteBg4,
          },
        }),
      },
      secondary: {
        background: white,
        '&:hover': {
          background: neutral4,
        },
      },
      accent: {
        background: background4,
        transition: '.3s ease-in',
        '&:hover': {
          background: background4,
          opacity: 0.7,
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkDefault: {
        color: 'inherit',
      },
      linkAccent: {
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            color: neutral4,
            background: 'transparent',
          },
        }),
      },
      default: {
        ...text2White,
        '&:hover': {
          color: text2,
          background: neutral4,
        },
      },
    },
    betslip: betslip(themeVars),
    NoBetsMessage: {
      root: {
        ...whiteBorder2,
        fontWeight: 'normal',
        fontSize: fontSizes.sm,
      },
    },
    Geoblock: {
      rootGeoblock: {
        background: '#eefbe4',
      },
      logoGeoblock: {
        background: '#89C64F',
      },
      textGeoblock: {
        color: '#0D4B24',
      },
    },
    sportsMenu: JSSThemeSportsMenu60013(themeVars),
    form: JSSThemeForm60013(themeVars),
    headerMobile: JSSThemeHeaderMobile60013(themeVars),
    banners: JSSThemeBannerItems60013(themeVars),
    headerMenu: JSSThemeHeaderMenu60013(themeVars),
    outcomes: JSSThemeOutcomes60013(themeVars),
    header: JSSThemeHeader60013(themeVars),
    authForm: JSSThemeAuthForm60013(themeVars),
    balance: JSSThemeBalance60013(themeVars),
    promotions: JSSThemePromotions60013(themeVars),
    sitemap: JSSThemeSitemap60013(themeVars),
    bonuses: JSSThemeBonuses60013(themeVars),
    coupons: JSSThemeCoupons60013(themeVars),
    tooltip: JSSThemeTooltip60013(themeVars),
    popularLeagues: JSSThemePopularLeagues60013(themeVars),
    casino: JSSThemeCasino60013(themeVars),
    search: JSSThemeSearch60013(themeVars),
    footer: JSSThemeFooter60013(themeVars),
    accountSection: JSSThemeAccountSection60013(themeVars),
    live: JSSThemeLive60013(themeVars),
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `none`,
        background: 'transparent',
        color: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: white,
        borderTop: `1px solid ${neutral1}`,
      },
      stakePotential: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: white,
        borderRadius: '2px',
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        background: white,
      },
      title: {
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxxl,
        },
      },
      desciption: {
        margin: [margins.md, 0],
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
      buttonWrap: {
        margin: [margins.xm, 0],
      },
    },
    resets: JSSTheme60013Resets(themeVars),
  };
};

export const getJSSThemePatterns60013: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: {
        whiteNeutral3,
        whitePrimary,
        whiteAccent,
        text2Neutral2,
        whiteBg4,
        whiteBg1,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...whitePrimary,
        ...applyOnlyDesktop({
          '&:hover': whiteNeutral3,
        }),
      },
      secondary: {
        ...text2Neutral2,
        ...applyOnlyDesktop({
          '&:hover': whiteBg4,
        }),
      },
      accent: {
        ...whiteAccent,
        ...applyOnlyDesktop({
          '&:hover': whiteBg1,
        }),
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu13: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: {
        accent,
        neutral1,
        white,
        text1,
        primary,
        background2,
        background5,
      },
    },
  } = themeVars;

  return {
    HorizontalSportsListUIBigIcons: {
      list: {
        background: accent,
      },
      sportLink: {
        padding: 0,
        color: neutral1,
      },
    },
    HorizontalSportsListUISmallIcons: {
      sportLink: {
        color: primary,
        padding: [margins.xs, margins.xs, margins.xs, 0],
        fontWeight: 'bold',
        '&.active, &:hover': {
          color: text1,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.xl,
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: text1,
        },
      },
      sportName: {
        fontWeight: 'bold',
        color: white,
        '&.active': {
          color: text1,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: text1,
        background: white,
      },
      title: {
        color: white,
      },
    },
    SportsMenuDesktop: {
      sport: {
        background: background5,
        '&:hover': {
          background: primary,
        },
        '&:active': {
          background: primary,
        },
      },
      region: {
        background: background2,
        padding: [margins.xs, margins.md, margins.xs, 34],
        fontSizes: fontSizes.xxl,
        '&:hover': {
          background: primary,
        },
        '&.active': {
          background: primary,
        },
        '&.focus': {
          background: primary,
        },
      },
    },
  };
};

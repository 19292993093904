// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile13: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorTypes: { background1, text2, background5, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: background1,
        boxShadow: `none`,
      },
      iconContainer: {
        color: white,
      },
      menuContainer: {
        background: text2,
      },
      logoImg: {
        width: 'auto',
        height: 26,
        margin: 3,
      },
      menuRight: {
        background: background5,
      },
    },
  };
};

// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme13,
  JSSThemeVariables13,
  getJSSThemePatterns13,
} from './JSSTheme13';
import { GeoblockPage13 } from './GeoblockPage13';
// $FlowIgnore
import '../../../styles/application-koltron.scss';
import Cookies from 'js-cookie';
import { changeCurrentLocale } from '../../utils';

if (
  !Cookies.get('lang') &&
  __OSG_CONFIG__.specificLocaleForNonAuthorizedUsers
) {
  changeCurrentLocale(__OSG_CONFIG__.specificLocaleForNonAuthorizedUsers);
}

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop13')).FullDesktop13,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile13')).FullMobile13,
  }),
  JSSTheme: JSSTheme13,
  JSSThemeVariables: JSSThemeVariables13,
  JSSThemePatterns: getJSSThemePatterns13,
  GeoblockPage: GeoblockPage13,
});

// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes13: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    colors: {
      colorTypes: { accent, background1, white, neutral1 },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      priceValue: {
        color: white,
      },
      root: {
        background: background1,
        color: white,
        '&.active': {
          background: accent,
        },
      },
    },
    Outcome: {
      root: {
        color: white,
        background: background1,
        '&.active': {
          background: accent,
        },
        '&.disabled': {
          cursor: 'default',
          color: white,
          background: neutral1,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: accent,
          },
        },
      },
    },
  };
};

// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses60013 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { white, primary, text2 },
      colorCombinations: { whitePrimary, whiteBg1 },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        background: text2,
        color: white,
        '&:hover': {
          background: primary,
          color: white,
        },
        '&.active': {
          background: primary,
        },
      },
      tabsItemMobile: {
        ...whitePrimary,
        borderRight: 'none',
        fontWeight: 'bold',
        '&.active': whiteBg1,
      },
      tabsContentDesktop: {
        padding: '10px 0',
      },
    },
    Bonuses: {
      root: {
        border: 'none',
      },
    },
  };
};

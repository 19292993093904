// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance13: JSSThemeDefaultBalanceType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { background5, white, background4, primary, background1 },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        padding: [margins.xs, margins.md],
        background: 'transparent',
        borderRadius: '0',
        marginRight: margins.xs,
        color: background4,
        transition: '.3s ease-in',
        fontWeight: 'bold',
        maxWidth: 143,
        '&:hover': {
          background: 'transparent',
          color: background4,
        },
        title: {
          fontSize: fontSizes.md,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '&:hover': {
            opacity: '1',
          },
        },
      },
      mobileUserMenuWrap: {
        color: white,
      },
      userMenuWrap: {
        padding: 0,
        background: background5,
        boxShadow: '3px 3px 40px rgba(0,0,0,.5)',
        '&:before': {
          background: background5,
        },
      },
      accountMenuWrap: {
        borderBottom: '0',
        borderTop: '0',
        padding: [0, 0, margins.md],
      },
      balanceWrap: {
        display: 'flex',
        justifyContent: 'center',
        padding: [margins.md, 0],
        margin: 0,
      },
      logoutWrap: {
        color: white,
        fontWeight: 'bold',
        padding: [margins.xs, margins.xm, margins.xm],
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    UsernameCompanent: {
      userName: {
        paddingTop: margins.md,
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
      accountNumber: {
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
      },
      bonusTitle: {
        color: white,
      },
      bonusValue: {
        color: primary,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        color: background1,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
    },
    AccountPageMenu: {
      userMenu: {
        padding: [margins.xs, 0, 0],
      },
      userMenuItem: {
        padding: [margins.md, margins.xm],
        color: white,
        '&:hover': {
          color: background1,
          background: 'transparent',
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        background: background4,
        padding: [margins.xm, 0],
      },
      userMenuItem: {
        color: white,
        fontWeight: 'bold',
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          color: background1,
          background: 'transparent',
        },
      },
    },
  };
};

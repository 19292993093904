// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm13: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { text2Text3 },
      colorTypes: { text3, text2, neutral2, black, neutral4, primary },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderRadius: borderRadius.sm,
        borderColor: neutral2,
        ...text2Text3,
        '&::placeholder': {
          color: text3,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${text3} inset !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        border: 'none',
        borderRadius: '2px',
        background: neutral4,
        color: black,
        '&::placeholder': {
          color: primary,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${neutral4} inset !important`,
          borderColor: `${neutral4} !important`,
          '-webkit-text-fill-color': `${black} !important`,
        },
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: black,
      },
    },
  };
};

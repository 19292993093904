// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons13: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1, background2, primary, background4 },
    },
  } = themeVars;
  return {
    SportTabs: {
      tabs: {
        padding: margins.md,
      },
      activeTab: {
        background: background1,
      },
      tab: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
        },
      },
    },
    Market: {
      date: {
        paddingLeft: margins.md,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: 'transparent',
        color: background2,
        fontWeight: 'bold',
        '&:hover': {
          background: 'transparent',
          color: primary,
        },
      },
    },
    WidgetSport: {
      sport: {
        padding: [0, margins.lg],
      },
      event: {
        margin: [margins.md, 0],
      },
    },
    Betbuilder: {
      exampleContainer: {
        background: '#fff',
      },
      toggler: {
        '&:hover': {
          background: background4,
        },
      },
    },
  };
};

// @flow
import type { JSSThemeDefaultResetsType } from '../JSSThemeDefaultResets';
import { applyOnlyDesktop } from '../jss-helpers';

export const JSSTheme60013Resets: JSSThemeDefaultResetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg2, whiteBorder1 },
    },
  } = themeVars;
  return {
    button: {
      borderRadius: '2px',
      lineHeight: '32px',
      padding: '0 15px',
      ...whiteBg2,
      ...applyOnlyDesktop({
        '&:hover': whiteBorder1,
      }),
    },
  };
};
